/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

window.printDiv = function(divId) {
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
    mywindow.document.write('<html><head><link href="' + "{{ asset('css/app.css') }}" + ' rel="stylesheet"><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById(divId).innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(function(){ 
        mywindow.print(); mywindow.close();
    }, 1000);
    return true;
}

// Initialize color scheme
window.initializeColourScheme = function(userPreference, prefersDarkMode) {
    // Retrieve user's preference from localStorage
    var storedPreference = localStorage.getItem('colorSchemePreference');

    // Initially apply the appropriate class based on system preference or stored preference
    if (storedPreference === 'dark' || prefersDarkMode) {
        swithToDarkMode();
    } else {
        if (userPreference === 'dark') {
            swithToDarkMode();
        } else if (userPreference === 'light') {
            swithToLightMode();
        } else {
            // If no preference is set, apply the light mode
            swithToLightMode();
        }
    }
}

// Toggle color scheme
window.changeColourScheme = function() {
    if ($('html').attr('data-bs-theme') === 'dark') {
        swithToLightMode();
        // Save the user's preference when they toggle it
        localStorage.setItem('colorSchemePreference', 'light');
    } else {
        swithToDarkMode();
        localStorage.setItem('colorSchemePreference', 'dark');
    }
}

function swithToDarkMode() {
    $('html').attr('data-bs-theme', 'dark');
    document.getElementById('toggleColorScheme').style.backgroundColor = 'gold';
    document.getElementById('toggleColorScheme').style.color = 'black';
    
    // summernote
    $('.note-editable').css('color', 'white');
    $('.note-editable').find('*').css('color', 'white'); // Apply changes to children
    $('.note-editor').css('border-color', 'white');
}

function swithToLightMode() {
    $('html').removeAttr('data-bs-theme');
    document.getElementById('toggleColorScheme').style.backgroundColor = '#212529';
    document.getElementById('toggleColorScheme').style.color = 'white';

    // summernote
    $('.note-editable').css('color', 'black');
    $('.note-editable').find('*').css('color', 'black'); // Apply changes to children
    $('.note-editor').css('border-color', 'black');
}


window.do_resize = function(text_box) {
    var txt = text_box.val();
    var cols = text_box.prop('cols');

    var array_txt = txt.split('\n');
    var rows = array_txt.length;

    if (rows > 5) {
        if (rows > 15) {
            rows = 15;
        }
        text_box.prop('rows', rows);
    }
}

window.copy = function(text, target) {
        
    setTimeout(function() {
        $('#copied_tip').remove();
    }, 800);

    $(target).append("<div class='tip' id='copied_tip'>Copied!</div>");

    var input = document.createElement('input');
    input.setAttribute('value', text);

    document.body.appendChild(input);
    input.select();

    var result = document.execCommand('copy');
    document.body.removeChild(input)
    
    return result;
}
